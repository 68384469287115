import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './navbar'
import Footer from './footer'
import "./layout.css"
import { graphql, useStaticQuery } from 'gatsby';
import Layout from './layout';
import { MDBContainer } from 'mdbreact'

const ErrorComp = ({ errorMsg }) => {
  console.log("yabo")
  console.log(errorMsg)
  if (typeof(errorMsg.location.state) !== "undefined") {
    return(
      <>
        <Layout navInfo={true} >
          <MDBContainer tag="section"  className="text-center my-5 errorSection">
            <h1 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">Oh No, {errorMsg.location.state.error.message}</h1>
            <h5 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">We are sorry for this inconvenience please 
            email at <a className="greenIcon" href="mailto:envapp@yahoo.com">envapp@yahoo.com</a> or call at <a className="greenIcon" href="tel:19736359404">(973)-635-9404</a> for assistance</h5>
          </MDBContainer>
        </Layout>
      </>
    );
  } else {
    return(
      <>
        <Layout navInfo={true} >
          <MDBContainer tag="section"  className="text-center my-5 errorSection">
            <h1 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">Oh No, Unknown Error</h1>
            <h5 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5 black-text">We are sorry for this inconvenience please 
            email at <a className="greenIcon" href="mailto:envapp@yahoo.com">envapp@yahoo.com</a> or call at <a className="greenIcon" href="tel:19736359404">(973)-635-9404 </a> for assistance</h5>
          </MDBContainer>
        </Layout>
      </>
    );
  }
}

export default ErrorComp
