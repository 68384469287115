import React, { Component } from 'react';
import ErrorComp from '../components/errorComp';


class App extends Component {
  render() {
    return (
      <>
        <ErrorComp errorMsg={this.props} />
      </>
    );
  }
}

export default App;
